<template>
  <button
    v-tooltip="tooltip"
    :class="btnClass"
    :type="type"
    :disabled="disabled || loading"
    @click="onClick"
  >
    <ButtonLoadingSpinnerIcon v-if="loading" class="mr-1" />
    <slot />
  </button>
</template>

<script setup lang="ts">
import { PropType } from "vue";

const props = defineProps({
  type: {
    type: String as PropType<"button" | "submit" | "reset">,
    default: "button",
  },
  classStr: {
    type: String,
    default:
      "rounded-md bg-primary-50 font-semibold text-primary-400 shadow-sm hover:bg-primary-100 shadow-sm ring-1 ring-inset ring-primary-400",
  },
  primary: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  neutral: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  xs: {
    type: Boolean,
    default: false,
  },
  lg: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String as PropType<string | null>,
    default: null,
  },
  outline: {
    type: Boolean,
    default: false,
  },
  stop: {
    type: Boolean,
    default: false,
  },
  prevent: {
    type: Boolean,
    default: false,
  },
});

const btnClass = computed(() => {
  let baseClass =
    "inline-flex items-center justify-center disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-500 disabled:ring-gray-200 ";

  if (props.xs) {
    baseClass += " px-3 py-1.5 text-xs ";
  } else if (props.lg) {
    baseClass += " px-3.5 py-2.5 text-sm ";
  } else {
    baseClass += " px-3 py-2 text-sm ";
  }

  if (props.primary && props.outline) {
    return (
      baseClass +
      "rounded-md bg-white font-semibold text-primary-400 shadow-sm ring-1 ring-inset ring-primary-400 hover:bg-primary-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-400"
    );
  }

  if (props.primary) {
    return (
      baseClass +
      "rounded-md bg-primary-400 font-semibold text-white shadow-sm hover:bg-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-400"
    );
  }

  if (props.error) {
    return (
      baseClass +
      "rounded-md bg-red-500 font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
    );
  }

  if (props.neutral) {
    return (
      baseClass +
      "rounded-md bg-white font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
    );
  }

  return baseClass + props.classStr;
});

const emit = defineEmits(["click"]);

const onClick = (evt: Event) => {
  if (props.stop) {
    evt.stopPropagation();
  }
  if (props.prevent) {
    evt.preventDefault();
  }
  emit("click");
};
</script>
